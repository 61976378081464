import React, { useEffect } from "react"
import { useStore } from "effector-react"

import ps from "state/programlDetailStore"

import Layout from "components/Layout/Layout"
import PageProgramDetail from "components/PageProgramDetail/PageProgramDetail"

const PageProgramPreview = ({ slug, lang }) => {
  const program = useStore(ps.program.store)
  const articles = useStore(ps.articles.store)

  useEffect(async () => {
    const program = await ps.program.fetch(slug)
    await ps.articles.fetch({
      [`translated_${lang}`]: true,
      program__in: program.id,
    })
  }, [slug])

  if (program.isLoading) return <h3>Загрузка...</h3>

  if (!program.id) return <h3>Ошибка загрузки </h3>

  return (
    <Layout color="white">
      <PageProgramDetail slug={slug} program={program} articles={articles} />
    </Layout>
  )
}

export default PageProgramPreview
