import React from "react"

import { useTranslation } from 'react-i18next'

import Track from 'components/common/Track/Track'
import Hero from './Hero/Hero'
import Parts from './Parts/Parts'
import NextPrevProgram from './NextPrevProgram/NextPrevProgram'
import NewsList from '../PageIndex/NewsList/NewsList'

import withBlackHeader from 'HOC/withBlackHeader'

import SEO from 'components/seo'

const PageProgramDetail = ({ program, articles }) => {
  const { i18n: { language } } = useTranslation()

  const items = [
    {
      label: program.goal.name,
      to: `/${language}/goals/${program.goal.slug}/`,
    },
    {
      label: program.title,
      to: `/${language}/programs/${program.slug}/`,
    }
  ]

  const {
    title, description, image, parts, next, prev, id,
  } = program

  return (
    <>
      <SEO title={program.metaTitle || title} description={program.metaDescription} key={program.metaKeywords} />
      <Track items={items} />
      <Hero title={title} description={description} image={image} />
      {parts && parts.length > 0 && <Parts items={parts} />}
      {(next || prev) && <NextPrevProgram next={next} prev={prev} />}
      {articles.results.length > 0 && <NewsList list={articles.results} count={articles.count} filter={{ program: id }} grey />}
    </>
  )
}

export default withBlackHeader(PageProgramDetail)
