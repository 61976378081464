import React from 'react'
import { Link } from 'gatsby'
import { useMediaQuery } from 'react-responsive'

import { useTranslation } from 'react-i18next'

import css from './Track.module.scss'

const Track = ({ items, date }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 650px)' })
  const { t, i18n: { language }} = useTranslation()
  const [first] = items
  return (
    <div className={css.media}>

      {!isMobile && <p className={css.desktop}><Link to={`/${language}`}>{t('x5 goals')}</Link>
        {items.map(item => <Link key={item.to} to={item.to}> &#8594; {item.label} </Link>)}
        {date && <span>{date}</span>}
      </p>}

      {isMobile && first && <p className={css.mobile}>
        <Link to={first.to}>&lt; {t('Back to')} {first.label}</Link>
      </p>}
    </div>
  )
}

export default Track
