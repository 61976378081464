import clone from 'lodash/clone'

import { translateItem, prepareItem, generator } from './utils'

const translateIt = (obj, fields, rootFields, lang) => clone(translateItem(obj, fields, translateIt, lang))
const prepareData = (data) => prepareItem(data)

const singleFabric = generator({ initialState: null, translateIt, prepareData })

export default singleFabric
