import React from 'react'

import { Router } from "@reach/router"

import PageProgramPreview from 'components/pageDataPreview/PageProgramPreview'

const ProgramPreview = () => (
  <Router basepath={`/en/program_preview`}>
    <PageProgramPreview path={'/:slug'} lang={'en'} />
  </Router>
)

export default ProgramPreview
