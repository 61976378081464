import React from 'react'

import { useTranslation } from 'react-i18next'

import css from './Parts.module.scss'

const Parts = ({ items }) => {
  const { t } = useTranslation()
  return <section className={css.container}>
    {items.map((item, index) => (
      <div className={css.item} key={index}>
        <div className={css.head}>
          <span className={css.number}>
            {index + 1}
          </span>
          <h2>{item.title}</h2>
        </div>
        <div className={css.text} dangerouslySetInnerHTML={{ __html: item.description }} />
        <div className={css.steps}>
          {item.steps.map((step, j) => (
            <div className={css.step} key={j}>
              <h3>{step.title}</h3>
              {step.image && step.image.length > 0 && <img src={step.image} alt={`${t('point')} ${j + 1}`}/>}
              <div className={css.stepText} dangerouslySetInnerHTML={{ __html: step.description }} />
            </div>
          ))}
        </div>
      </div>
    ))}
  </section>
}

export default Parts
