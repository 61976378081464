import React from 'react'

import { Link } from 'gatsby'

import { useTranslation } from 'react-i18next'

import css from './NextPrevProgram.module.scss'

const NextPrevProgram = ({ next, prev }) => {
  const { t, i18n: { language }} = useTranslation()
  return (
    <section className={css.container}>
      {prev && (
        <Link to={`/${language}/programs/${prev.slug}/`} className={css.prev}>
          {t('Previous program')}
          <span className={css.title}>
            {prev.title} <span>→</span>
          </span>
        </Link>
      )}
      {next && (
        <Link to={`/${language}/programs/${next.slug}/`} className={css.next}>
          {t('Next program')}
          <span className={css.title}>
            {next.title}{' '}<span>→</span>
          </span>
        </Link>
      )}
    </section>
  )
}

export default NextPrevProgram
