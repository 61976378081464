import React from 'react'

import css from './Hero.module.scss'

const Hero = ({ title, description, image }) => {
  return (
    <div className={css.container}>
      <div className={css.content}>
        <h1 className="h2">{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      {image && image.length > 0 && (
        <img src={image} alt={title} className={css.image} />
      )}
    </div>
  )
}

export default Hero
