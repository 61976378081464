import { getProgram, getArticles } from '../api'

import generator from './fabrics/single'
import generatorList from './fabrics/list'

const programFabric = generator({
  title: 'program-detail',
  fetch: getProgram,
  fieldsToTranslate: [
    'title',
    'description',
    'previewDescription',
    'content',
    'metaDescription',
    'metaKeywords',
    'metaTitle',
    { key: 'goal', fieldsToTranslate: [ 'name' ] },
    { key: 'prev', fieldsToTranslate: [ 'title' ] },
    { key: 'next', fieldsToTranslate: [ 'title' ] },
    { key: 'parts', fieldsToTranslate: [
        'description',
        'title',
        { key: 'steps', fieldsToTranslate: ['title', 'description']},
      ]
    },
    { key: 'articles', fieldsToTranslate: [ 'title' ] },
    { key: 'translatedArticles', fieldsToTranslate: [ 'title' ] },
  ],
})

const programArticlesFabric = generatorList({
  title: 'program-detail-articles',
  fetch: getArticles,
  fieldsToTranslate: ['title'],
})

export default {
  program: {
    fetch: programFabric.fetchEffect,
    store: programFabric.store,
    getTranslation: programFabric.getTranslation,
  },
  articles: {
    fetch: programArticlesFabric.fetchEffect,
    store: programArticlesFabric.store,
    getTranslation: programArticlesFabric.getTranslation,
  },
}
